<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div v-if="!loadingBusiness">
              <ValidationObserver
                v-if="business"
                v-slot="{ handleSubmit, invalid, touched }"
                ref="updateForm"
              >
                <form
                  class="authentication-form"
                  @submit.prevent="handleSubmit(updateBusiness)"
                >
                  <div class="row">
                    <div class="col-lg-4">
                      <TextInput
                          v-model="business.business_name"
                          name="business_name"
                          rules="required"
                          label="Business Name"
                      />
                    </div>
                    <div class="col-lg-5">
                      <div class="row">
                        <div class="col-md-6">
                          <TextInput
                              v-model="business.address"
                              name="business_address"
                              rules="required"
                              label="Address"
                          />
                        </div>
                        <div class="col-md-6">
                          <TextInput
                              v-model="business.city"
                              name="business_city"
                              rules="required"
                              label="City"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3">
                      <div class="row">
                        <div class="col-md-5">
                          <TextInput
                              v-model="business.state"
                              name="business_state"
                              rules="required"
                              label="State"
                          />
                        </div>
                        <div class="col-md-7">
                          <TextInput
                              v-model="business.zip"
                              name="business_zip"
                              rules="required"
                              label="Zip"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="row">
                        <div class="col-md-6">
                          <label>Business Phone</label>
                          <VuePhoneNumberInput v-model="business.business_phone" valid-color="#e2e7f1" />
<!--                          <TextInput
                              v-model="business.business_phone"
                              v-mask="'(###) ###-####'"
                              name="business_phone"
                              rules="required|phone_number"
                              label="Business Phone"
                          />-->
                        </div>
                        <div class="col-md-6">
                          <TextInput
                              v-model="business.business_website"
                              name="business_website"
                              rules="required"
                              label="Website URL"
                          ><a :href="business.business_website" target="_blank" class="btn btn-dark-blue link-target align-middle"><Feather class="link-eye" type="eye" /></a></TextInput>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="row">
                          <div class="col-md-6">
                            <TextInput
                                v-model="business.date"
                                name="date"
                                label="Date Created"
                                disabled
                            />
                          </div>
                          <div class="col-md-6">
                            <TextInput
                                v-model="business.types"
                                name="categories"
                                label="Primary Category"
                            />
                          </div>
                        </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="row btn-links">
                        <div class="col-sm-6">
                          <label class="d-block">Review Link</label>
                          <a class="btn btn-dark-blue form-control" target="_blank" :href="'https://u.reviewour.biz/r/' + business.unique_id"><span>View Business Review Link</span> <Feather class="ml-2 hidden-sm hidden-xs" type="eye" /></a>
                        </div>
                        <div class="col-sm-6">
                          <label class="d-block">Maps Link</label>
                          <a class="btn btn-dark-blue form-control" target="_blank" :href="business.google_maps_url"><span>View Business On Maps</span> <Feather class="ml-2 hidden-sm hidden-xs" type="eye" /></a>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6 mt-lg-0 mt-md-3 mt-sm-3">
                      <div class="d-inline-flex align-items-center">
                        <TextInput
                            v-model="business.unique_id"
                            name="unique_id"
                            rules="required|subdomain"
                            label="Landing Page"
                            linkstart="https://u.reviewour.biz/r/"
                        ><button type="button" class="btn btn-dark-blue btn-sm copy-link" @click="copyLink"><Feather type="copy" /></button></TextInput>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <TextInput
                          v-model="business.origin"
                          name="origin"
                          label="Originating URL"
                      />
                    </div>
                  </div>
                  <hr />
                  <div class="row">
                    <div class="col-md-6 col-lg-3">
                      <TextInput
                        v-model="business.first_name"
                        name="first_name"
                        rules="required"
                        label="First Name"
                      />
                    </div>
                    <div class="col-md-6 col-lg-3">
                      <TextInput
                        v-model="business.last_name"
                        name="last_name"
                        rules="required"
                        label="Last Name"
                      />
                    </div>
                    <div class="col-md-6 col-lg-3">
                      <label>Phone Number</label>
                      <VuePhoneNumberInput v-model="business.phone_number" :error="phoneError" valid-color="#e2e7f1" rules="required" />
                    </div>
                    <div class="col-md-6 col-lg-3">
                      <TextInput
                          v-model="business.email"
                          name="owner.email"
                          rules="required|email"
                          label="Email"
                      />
                    </div>
                  </div>
                  <b-form-group>
                    <router-link :to="{ name: 'agency.widgets.index', query: { 'create_widget': true, 'business_name': encodeURI(business.business_name) + ', ' + encodeURI(business.city) + ', ' + encodeURI(business.zip) } }" class="btn btn-success border-0 mr-4 btn-create-widget">
                      Create Widget
                    </router-link>
                    <span v-if="(invalid && touched)"></span>
                    <b-button
                      variant="primary"
                      class="btn-dark-blue float-right"
                      :disabled="loadingUpdate"
                      type="submit"
                    >
                      <b-spinner v-if="loadingUpdate" small />
                      <span v-else>Update</span>
                    </b-button>
                  </b-form-group>
                </form>
              </ValidationObserver>
              <div class="row">
                <div class="col">
                  <div v-if="exampleReviews.length" class="business-reviews">
                    <div id="rwg-example-slider" :key="business.id" class="rwg-app-example-slider" :data-place-id="business.place_id"></div>
                  </div>
                  <div v-else class="text-center"><h3>No Reviews</h3></div>
                </div>
              </div>
            </div>
            <div v-else class="text-center">
              <b-spinner></b-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
export default {
  components:{
    VuePhoneNumberInput
  },
  data() {
    return {
      countries: [{ key: 'US', label: 'United States of America' }, { key: 'CA', label: 'Canada' }],
      loadingBusiness: false,
      business: null,
      loadingUpdate: false,
      loadingPassword: false,
      phoneError:false,
      password: {
        password: '',
        password_confirmation: '',
      },
      open:false,
      exampleReviews:[]
    }
  },

  computed: {
    timezones() {
      return this.$store.getters['app/timezones']
    },

    currencies() {
      return this.$store.getters['app/currencies']
    },

    dateFormats() {
      return this.$store.getters['app/dateFormats']
    },

    onlyCountries() {
      return [ this.business.country ]
    },

    customLink(){
      return 'https://u.reviewour.biz/r/'+this.business.unique_id;
    }
  },
  metaInfo () {
    return {
      script: [{
        src: `${process.env.NODE_ENV === 'development' ? 'https://review-widget-app.dev/widget/dist/index.0641b553.js' : 'https://app.reviewwidgetgenerator.com/widget/dist/index.js'}`,
        async: true,
        defer: true
      }]
    }
  },
  mounted() {
    this.$nextTick();
    this.getBusiness();
  },

  methods: {
    copyLink() {
      navigator.clipboard.writeText(this.customLink);
      Vue.$toast.open({
        message: 'Copied',
        type: 'success',
        duration: 5000,
      })
    },

    updateBusiness() {
      this.loadingUpdate = true
      this.phoneError = false;
      this.$store
        .dispatch('business/update', this.business)
        .then(() => {
          this.loadingUpdate = false
        })
        .catch((err) => {
          if (err.response.data.errors) {
            this.$refs.updateForm.setErrors(err.response.data.errors)
            if(Object.keys(err.response.data.errors).includes('phone_number')){
              this.phoneError = true;
            }
          }
          this.loadingUpdate = false
        })
    },

    getBusiness() {
      this.loadingBusiness = true
      this.$store
        .dispatch('business/find', this.$route.params.id)
        .then((business) => {
          this.loadingBusiness = false;
          this.exampleReviews = business.reviews;
          this.$nextTick();
          business.message_limit = business.no_messages != null
          business.contact_limit = business.no_contacts != null
          this.business = business
          const rwgReviews = {
              current_page:1,
              data: business.reviews,
              next_page_url:null
          };
          sessionStorage.setItem('rwg_' + business.place_id,JSON.stringify({
            place_id:business.place_id,
            business_name:business.business_name,
            rating:5,
            total_review:1,
            reviews:rwgReviews
          }));
          setTimeout(function(){
            document.documentElement.dispatchEvent(new CustomEvent('setupRWG'));
          }, 100);
        })
        .catch(() => {
          this.$router.push({ name: 'agency.businesses.index' })
          this.loadingBusiness = false
        }).finally(() => {
      })
    },

    changePassword() {
      this.loadingPassword = true

      this.$store
        .dispatch('user/changePassword', {
          ...this.password,
          user_id: this.business.owner.id,
        })
        .then(() => {
          this.password = {}
          this.loadingPassword = false
          this.$refs.passwordForm.reset()
        })
        .catch((err) => {
          if (err.response.data.errors) {
            this.$refs.passwordForm.setErrors(err.response.data.errors)
          }
          this.loadingPassword = false
        })
    },
  },
}
</script>
<style lang="scss" scoped>
  @import url('https://unpkg.com/vue-tel-input@5.12.0/dist/vue-tel-input.css');
  i.feather--eye{
    margin-top: -3px;
  }
  .btn-links .btn{
    white-space: nowrap;
   span{
      vertical-align: top;
    }
  }
  input[name="unique_id"] {
    max-width: 150px !important;
  }
  .copy-link, .link-target{
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .link-target{
    max-height: 39px;
  }
  .business-reviews{
    border-top: 2px solid #ccc;
    padding-top: 20px;
  }
</style>
<style lang="scss">
.business-reviews{
  .rwg-app-example-slider{
    .rwg-title{
      display: none;
    }
    .rwg-review-header{
      display: none;
    }
  }
}
#MazPhoneNumberInput {
  label.input-tel__label, label.country-selector__label {
    color: #4b4b5a !important;
    font-weight: normal;
  }
}
</style>
